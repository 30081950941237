import { useMutation } from '@tanstack/react-query';

import * as partnerApi from '@/apis/universal';
import type { CommonUseMutationOptions } from '@/types/app';
import type { LoginParams, LoginResponse } from '@/types/universal';

export const useLogin = (
  options?: CommonUseMutationOptions<LoginParams, LoginResponse>
) => {
  const mutation = useMutation({
    mutationFn: (params) => partnerApi.login(params),
    ...options,
  });

  return mutation;
};
