import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

import Table from '@/components/Table';
import TooltipText from '@/components/TooltipText';
import { useBranchDailyShares } from '@/hooks/queries/universal';
import usePagination from '@/hooks/usePagination';
import { selectedBranchIdAtom } from '@/store/atoms';

const ShareDaily = () => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const pagination = usePagination();
  const query = useBranchDailyShares(
    {
      branchId: selectedBranchId as number,
      ...pagination.query,
    },
    {
      enabled: selectedBranchId !== null,
    }
  );

  return (
    <Table
      columns={[
        {
          width: '50%',
          dataIndex: 'recordedAt',
          title: '일별',
          render: (value) => dayjs(value).format('YYYY-MM-DD'),
        },
        {
          width: '50%',
          dataIndex: 'unitShare',
          title: (
            <TooltipText
              description={
                '총 유닛의 부피를 기준으로,\n사용 중인 유닛의 부피 비율을 나타냅니다'
              }
            >
              점유율
            </TooltipText>
          ),
          render: (value) => `${value}%`,
        },
      ]}
      dataSource={query.data?.results.map((item) => ({
        recordedAt: item.recordedAt,
        unitShare: item.unitShare,
      }))}
      rowKey={(row) => row.recordedAt}
      pagination={{
        total: query.data?.count ?? 0,
        ...pagination,
      }}
    />
  );
};

export default ShareDaily;
