import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

import Table from '@/components/Table';
import TooltipText from '@/components/TooltipText';
import { useBranchDailyOperationRates } from '@/hooks/queries/universal';
import usePagination from '@/hooks/usePagination';
import { selectedBranchIdAtom } from '@/store/atoms';

const ShareDaily = () => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const pagination = usePagination();
  const query = useBranchDailyOperationRates(
    {
      branchId: selectedBranchId as number,
      ...pagination.query,
    },
    {
      enabled: selectedBranchId !== null,
    }
  );

  return (
    <Table
      columns={[
        {
          width: '25%',
          dataIndex: 'recordedAt',
          title: '일별',
          render: (value) => dayjs(value).format('YYYY-MM-DD'),
        },
        {
          width: '25%',
          dataIndex: 'totalSoldCount',
          title: '이용 중인 유닛',
          render: (value) => `${value}개`,
        },
        {
          width: '25%',
          dataIndex: 'totalCount',
          title: '전체 유닛',
          render: (value) => `${value}개`,
        },
        {
          width: '25%',
          dataIndex: 'operationRate',
          title: (
            <TooltipText
              description={
                '사용 중인 유닛의 개수를 기준으로,\n전체 유닛 대비 가동률을 나타냅니다'
              }
            >
              가동률
            </TooltipText>
          ),
          render: (value) => `${value}%`,
        },
      ]}
      dataSource={query.data?.results}
      rowKey={(row) => row.recordedAt}
      pagination={{
        total: query.data?.count ?? 0,
        ...pagination,
      }}
    />
  );
};

export default ShareDaily;
