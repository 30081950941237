import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { selectedBranchIdAtom } from '@/store/atoms';

interface DefaultPagination {
  page: number;
  size: number;
}

const DEFAULT_SIZE = 20;
const DEFAULT_PAGE = 1;

const usePagination = <AdditionalParams extends Record<string, unknown>>(
  keys: (keyof AdditionalParams)[] = [] // 주소창 queryString에 사용할 키 배열
) => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const prevSelectedBranchId = useRef(selectedBranchId);
  const navigate = useNavigate();
  const { search } = useLocation();
  const getPagination = () => {
    const params = new URLSearchParams(search);

    return [
      ['page', params.get('page') ?? DEFAULT_PAGE],
      ['size', params.get('size') ?? DEFAULT_SIZE],
      ...keys.map<[string, string | undefined]>((key) => [
        key as string,
        params.get(key as string) ?? undefined,
      ]),
    ].reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]:
            value === 'true'
              ? true
              : value === 'false'
                ? false
                : !Number.isNaN(Number(value))
                  ? Number(value)
                  : value,
        };
      },
      {} as Partial<AdditionalParams> & DefaultPagination
    );
  };

  const [pagination, setPagination] = useState(getPagination());

  useEffect(() => {
    if (
      selectedBranchId !== null &&
      prevSelectedBranchId.current !== null &&
      selectedBranchId !== prevSelectedBranchId.current
    ) {
      navigate({ search: '' });
    }
    prevSelectedBranchId.current = selectedBranchId;
  }, [selectedBranchId]);

  useEffect(() => {
    setPagination(getPagination());
  }, [search]);

  const onPartialChange = <K extends keyof AdditionalParams>(
    key: K,
    value: AdditionalParams[K]
  ) => {
    onChange({
      [key]: value,
    } as unknown as Partial<AdditionalParams>);
  };

  const onChange = (newValues: Partial<AdditionalParams>) => {
    const params = new URLSearchParams();

    Object.entries(pagination).forEach(([key, value]) => {
      // pagination 상태값이 빈값이 아니라면 params에 세팅
      if (value !== null && value !== undefined && value !== '') {
        params.set(key, String(value));
      }
    });

    Object.entries(newValues).forEach(([key, value]) => {
      // 새로 세팅하려는 값이 빈값이라면 params에서 제거
      if (value === null || value === undefined || value === '') {
        params.delete(key);
      } else {
        params.set(key, String(value));
      }
    });

    // page, size를 제외한 값이 변경되었거나 size에 변화가 생겼을 때 page 초기화
    if (
      Object.keys(newValues).filter(
        (item) => item !== 'page' && item !== 'size'
      ).length > 0 ||
      newValues.size !== pagination.size
    ) {
      params.set('page', String(DEFAULT_PAGE));
    }

    // params 일괄 적용
    navigate({ search: params.toString() });
  };

  return {
    query: {
      ...pagination,
    },
    setPaginationValue: onPartialChange,
    setPaginationValues: onChange,
  };
};

export default usePagination;
