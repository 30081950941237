import { Segmented } from 'antd';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import tw from 'twin.macro';

import { PATH } from '@/Route';

const ShareIndex = (props: unknown) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === PATH.BRANCH_SHARE.INDEX) {
      navigate(PATH.BRANCH_SHARE.DAILY, { replace: true });
    }
  }, [location, navigate]);

  return (
    <div css={tw`flex flex-col items-start`}>
      <Segmented
        width="auto"
        options={[
          {
            value: PATH.BRANCH_SHARE.DAILY,
            label: '날짜별 점유율',
          },
          {
            value: PATH.BRANCH_SHARE.DAILY_OPERATION,
            label: '날짜별 가동률',
          },
          {
            value: PATH.BRANCH_SHARE.UNIT,
            label: '유닛별 가동률',
          },
          {
            value: PATH.BRANCH_SHARE.UNIT_COMPOSITION,
            label: '유닛 구성',
          },
        ]}
        value={location.pathname}
        onChange={(value) => navigate(value)}
      />

      <div css={tw`mt-[32px] w-full`}>
        <Outlet />
      </div>
    </div>
  );
};

export default ShareIndex;
