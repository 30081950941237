import { BrowserRouter, Route, Routes } from 'react-router';

import { ContractIcon, MoneyIcon, RateIcon } from '@/assets/svg';
import { useMyPermissions } from '@/hooks/queries/universal';
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout';
import GuestLayout from '@/layouts/GuestLayout';
import AdjustmentIndex from '@/pages/AdjustmentIndex';
import ContractIndex from '@/pages/ContractIndex';
import Login from '@/pages/Login';
import Logout from '@/pages/Logout';
import NotFound from '@/pages/NotFound';
import ShareDaily from '@/pages/share/ShareDaily';
import ShareDailyOperation from '@/pages/share/ShareDailyOperation';
import ShareIndex from '@/pages/share/ShareIndex';
import ShareUnit from '@/pages/share/ShareUnit';
import ShareUnitComposition from '@/pages/share/ShareUnitComposition';
import type {
  ChildRoute,
  ClientRouteKey,
  RouteKey,
  RouteMap,
} from '@/types/app';

// 메뉴별 부모 카테고리
export const MENU_GROUPS = [
  {
    label: '파트너 메뉴',
    key: 'PARTNER',
  },
] as const;

// 페이지의 path or url
export const PATH = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  CURRENT_CONTRACT: {
    INDEX: '/contract',
  },
  BRANCH_SHARE: {
    INDEX: '/share',
    DAILY: '/share/daily',
    DAILY_OPERATION: '/share/dailyOperation',
    UNIT: '/share/unit',
    UNIT_COMPOSITION: '/share/unitComposition',
  },
  ADJUSTMENT: {
    INDEX: '/adjustment',
  },
} as const;

// 메뉴별 부모 카테고리, path, icon, page component 지정
export const ROUTES: RouteMap = {
  LOGIN: {
    title: '로그인',
    path: PATH.LOGIN,
    element: <Login />,
  },
  LOGOUT: {
    title: '로그아웃',
    path: PATH.LOGOUT,
    element: <Logout />,
  },
  CURRENT_CONTRACT: {
    title: '판매 현황',
    menuGroupKey: 'PARTNER',
    path: PATH.CURRENT_CONTRACT.INDEX,
    icon: <ContractIcon />,
    element: <ContractIndex />,
    useBranchSelector: true,
  },
  BRANCH_SHARE: {
    title: '지점 점유율',
    menuGroupKey: 'PARTNER',
    path: PATH.BRANCH_SHARE.INDEX,
    icon: <RateIcon />,
    element: <ShareIndex />,
    useBranchSelector: true,
    children: [
      { path: PATH.BRANCH_SHARE.DAILY, element: <ShareDaily /> },
      {
        path: PATH.BRANCH_SHARE.DAILY_OPERATION,
        element: <ShareDailyOperation />,
      },
      { path: PATH.BRANCH_SHARE.UNIT, element: <ShareUnit /> },
      {
        path: PATH.BRANCH_SHARE.UNIT_COMPOSITION,
        element: <ShareUnitComposition />,
      },
    ],
  },
  ADJUSTMENT: {
    title: '정산 내역',
    menuGroupKey: 'PARTNER',
    path: PATH.ADJUSTMENT.INDEX,
    icon: <MoneyIcon />,
    element: <AdjustmentIndex />,
  },
} as const;

const guestRouteKeys: string[] = ['LOGIN'] as RouteKey[]; // GuestLayout을 사용할 키 목록
const clientRouteKeys: string[] = ['LOGIN', 'LOGOUT'] as ClientRouteKey[]; // 권한 체크를 하지 않을 라우트 키 목록

export default () => {
  const { availableMenuKeys } = useMyPermissions();

  const renderChildren = (children?: ChildRoute[]) => {
    if (!children) return null;

    return children.map((child) => (
      <Route key={child.path} path={child.path} element={child.element}>
        {renderChildren(child.children)}
      </Route>
    ));
  };

  return (
    <BrowserRouter>
      <Routes>
        {Object.entries(ROUTES)
          .filter(
            ([key]) =>
              clientRouteKeys.includes(key) || availableMenuKeys.includes(key) // 권한체크를 하지 않는 화면이거나 권한이 있는 메뉴만 필터링
          )
          .map(([key, route]) => {
            const isGuest = guestRouteKeys.includes(key);

            return (
              <Route
                key={key}
                path={route.path}
                element={
                  isGuest ? (
                    <GuestLayout>{route.element}</GuestLayout>
                  ) : (
                    <AuthenticatedLayout
                      title={route.title}
                      useBranchSelector={route.useBranchSelector}
                    >
                      {route.element}
                    </AuthenticatedLayout>
                  )
                }
                index={false}
              >
                {renderChildren(route.children)}
              </Route>
            );
          })}

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
