import { Badge, DatePicker, Select, Tag, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import tw from 'twin.macro';

import { NoticeIcon } from '@/assets/svg';
import Notice from '@/components/Notice';
import Table from '@/components/Table';
import TooltipText from '@/components/TooltipText';
import { CONTRACT_STATUS, CUSTOMER_TYPE } from '@/constants/contract';
import { UNIT_TYPE } from '@/constants/unit';
import { useContracts } from '@/hooks/queries/universal';
import usePagination from '@/hooks/usePagination';
import { selectedBranchIdAtom } from '@/store/atoms';
import type { GetContractsParams } from '@/types/universal';

const ContractIndex = () => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const pagination = usePagination<Omit<GetContractsParams, 'branchId'>>([
    'contractStatus',
    'clientType',
    'contractStartAtAfter',
    'contractStartAtBefore',
    'contractEndAtAfter',
    'contractEndAtBefore',
  ]);
  const query = useContracts(
    {
      branchId: selectedBranchId as number,
      ...pagination.query,
    },
    {
      enabled: !!selectedBranchId,
    }
  );

  return (
    <div css={tw`flex flex-col gap-[32px]`}>
      <Notice icon={<NoticeIcon />}>
        할인율은 정가 기준에서 적용된 할인율입니다. 다이나믹 프라이싱, 프로모션
        적용에 따라 할인율이 달라집니다. 프로모션은 쿠폰 적용 여부를 표시합니다.
      </Notice>

      <div
        css={[
          tw`flex flex-row items-center justify-between gap-[12px] flex-wrap overflow-x-auto`,
          tw`mobile:(flex-col items-start)`,
        ]}
      >
        <div css={tw`flex flex-row items-center gap-[12px] w-max`}>
          <Select
            placeholder="계약 상태"
            value={pagination.query.contractStatus}
            onChange={(value) =>
              pagination.setPaginationValue('contractStatus', value)
            }
          >
            {Object.entries(CONTRACT_STATUS).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
          <Select
            placeholder="회원 유형"
            value={pagination.query.clientType}
            onChange={(value) =>
              pagination.setPaginationValue('clientType', value)
            }
          >
            {Object.entries(CUSTOMER_TYPE).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div css={tw`flex flex-row items-center gap-[12px] w-max`}>
          <DatePicker.RangePicker
            picker="date"
            placeholder={['계약 시작일', '계약 시작일']}
            value={[
              pagination.query.contractStartAtAfter
                ? dayjs(pagination.query.contractStartAtAfter)
                : null,
              pagination.query.contractStartAtBefore
                ? dayjs(pagination.query.contractStartAtBefore)
                : null,
            ]}
            onChange={(_, [startDate, endDate]) => {
              pagination.setPaginationValues({
                contractStartAtAfter: startDate,
                contractStartAtBefore: endDate,
              });
            }}
            presets={[
              { label: '1주일', value: [dayjs().subtract(1, 'week'), dayjs()] },
              {
                label: '1개월',
                value: [dayjs().subtract(1, 'month'), dayjs()],
              },
              {
                label: '3개월',
                value: [dayjs().subtract(3, 'month'), dayjs()],
              },
              {
                label: '6개월',
                value: [dayjs().subtract(6, 'month'), dayjs()],
              },
            ]}
          />
          <DatePicker.RangePicker
            picker="date"
            placeholder={['계약 종료일', '계약 종료일']}
            value={[
              pagination.query.contractEndAtAfter
                ? dayjs(pagination.query.contractEndAtAfter)
                : null,
              pagination.query.contractEndAtBefore
                ? dayjs(pagination.query.contractEndAtBefore)
                : null,
            ]}
            onChange={(_, [startDate, endDate]) => {
              pagination.setPaginationValues({
                contractEndAtAfter: startDate,
                contractEndAtBefore: endDate,
              });
            }}
            presets={[
              { label: '1주일', value: [dayjs().subtract(1, 'week'), dayjs()] },
              {
                label: '1개월',
                value: [dayjs().subtract(1, 'month'), dayjs()],
              },
              {
                label: '3개월',
                value: [dayjs().subtract(3, 'month'), dayjs()],
              },
              {
                label: '6개월',
                value: [dayjs().subtract(6, 'month'), dayjs()],
              },
            ]}
          />
        </div>
      </div>

      <Table
        columns={[
          { dataIndex: 'id', title: 'No', fixed: 'left' },
          {
            dataIndex: 'unitType',
            title: '유닛 타입',
            fixed: 'left',
            render: (value) => UNIT_TYPE[value as keyof typeof UNIT_TYPE],
          },
          { dataIndex: 'unitName', title: '유닛 번호', fixed: 'left' },
          {
            dataIndex: 'contractStatus',
            title: '계약 상태',
            render: (value, record) => (
              <Badge
                status={(() => {
                  switch (record.contractStatus) {
                    case 'RESERVED':
                      return 'success';
                    case 'CANCELED':
                      return 'error';
                    case 'CONTRACT_END':
                      return 'warning';
                    default:
                      return 'default';
                  }
                })()}
                text={CONTRACT_STATUS[value as keyof typeof CONTRACT_STATUS]}
              />
            ),
          },
          {
            dataIndex: 'period',
            title: (
              <TooltipText
                description={
                  '계약 시작일: 사용자가 계약을 체결한 날짜\n이용 시작일: 유닛을 사용하기로 한 날짜'
                }
              >
                계약 / 이용일
              </TooltipText>
            ),
            render: (_, record) => {
              const timelineItems = [
                record.contractStartAt && {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        계약 시작일
                      </Typography.Text>
                      {dayjs(record.contractStartAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
                record.usageStartAt && {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        이용 시작일
                      </Typography.Text>
                      {dayjs(record.usageStartAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
                record.contractEndAt && {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        계약 종료일
                      </Typography.Text>
                      {dayjs(record.contractEndAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
                record.usageEndAt && {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        이용 종료일
                      </Typography.Text>
                      {dayjs(record.usageEndAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
              ].filter((item) => !!item);

              return (
                <div
                  css={tw`[.ant-timeline-item-content]:(flex flex-row gap-[12px])`}
                >
                  <Timeline items={timelineItems} />
                </div>
              );
            },
          },
          {
            dataIndex: 'clientType',
            title: '회원 유형',
            align: 'center',
            render: (value) => (
              <Tag>{CUSTOMER_TYPE[value as keyof typeof CUSTOMER_TYPE]}</Tag>
            ),
          },
          {
            dataIndex: 'originPrice',
            title: '정가',
            align: 'right',
            render: (_, row) => row.originPrice?.toLocaleString(),
          },
          {
            dataIndex: 'discountedRate',
            title: '할인율',
            align: 'right',
            render: (_, row) => `${row.discountedRate}%`,
          },
          {
            dataIndex: 'discountedPrice',
            title: '판매가',
            align: 'right',
            render: (_, row) => row.discountedPrice?.toLocaleString(),
          },
          {
            dataIndex: 'isUsedPromotion',
            title: '프로모션',
            align: 'center',
            render: (value) => <Tag>{value ? '적용' : '미적용'}</Tag>,
          },
        ]}
        dataSource={query.data?.results}
        rowKey={(row) => row.id}
        pagination={{
          total: query.data?.count,
          ...pagination,
        }}
      />
    </div>
  );
};

export default ContractIndex;
