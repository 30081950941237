import { useAtomValue } from 'jotai';
import { useState } from 'react';
import tw from 'twin.macro';

import Table from '@/components/Table';
import TooltipText from '@/components/TooltipText';
import { UNIT_TYPE } from '@/constants/unit';
import { useUnitOperationRates } from '@/hooks/queries/universal';
import { selectedBranchIdAtom } from '@/store/atoms';

const ShareOperation = () => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const query = useUnitOperationRates(selectedBranchId as number, {
    enabled: selectedBranchId !== null,
  });
  const [sortOrder, setSortOrder] = useState<'ascend' | 'descend'>('descend');

  return (
    <Table
      onChange={() => {
        setSortOrder(sortOrder === 'ascend' ? 'descend' : 'ascend');
      }}
      columns={[
        {
          width: '25%',
          dataIndex: 'unitType',
          title: '유닛 타입',
          fixed: 'left',
          render: (value) => UNIT_TYPE[value as keyof typeof UNIT_TYPE],
        },
        {
          width: '25%',
          dataIndex: 'totalUnitTypeSoldCount',
          title: '이용 중인 유닛',
          render: (value) => `${value}개`,
        },
        {
          width: '25%',
          dataIndex: 'totalUnitTypeCount',
          title: '수량',
          render: (value) => `${value}개`,
        },
        {
          width: '25%',
          dataIndex: 'operationRate',
          title: (
            <TooltipText
              description={
                '사용 중인 유닛의 개수를 기준으로,\n유닛 타입별 가동률을 나타냅니다'
              }
            >
              가동률
            </TooltipText>
          ),
          render: (value) => `${value}%`,
          sorter: (a, b) => a.operationRate - b.operationRate,
          sortOrder,
        },
      ]}
      dataSource={query.data}
      summary={(data) => {
        const totalUnitTypeSoldCount = data.reduce((acc, row) => {
          return acc + row.totalUnitTypeSoldCount;
        }, 0);
        const totalUnitTypeCount = data.reduce((acc, row) => {
          return acc + row.totalUnitTypeCount;
        }, 0);

        return (
          <tr>
            <td css={tw`(sticky z-[2] left-0)!`}>합계</td>
            <td>{totalUnitTypeSoldCount}개</td>
            <td>{totalUnitTypeCount}개</td>
            <td>
              {totalUnitTypeCount === 0
                ? 0
                : ((totalUnitTypeSoldCount / totalUnitTypeCount) * 100).toFixed(
                    1
                  )}
              %
            </td>
          </tr>
        );
      }}
      rowKey={(row) => row.unitType}
    />
  );
};

export default ShareOperation;
