import { Tag, Typography } from 'antd';
import tw from 'twin.macro';

import { USER_TYPE } from '@/constants/user';
import { useMyInformation } from '@/hooks/queries/universal';

const Account = () => {
  const getMyInformation = useMyInformation();

  if (!getMyInformation.data) {
    return null;
  }

  const { data } = getMyInformation;

  return (
    <div css={tw`flex flex-row items-center gap-[8px]`}>
      <Tag color={data.userType === 'ADMIN' ? 'green' : 'blue'}>
        {USER_TYPE[data.userType]}
      </Tag>
      <Typography.Text ellipsis>{data.email}</Typography.Text>
    </div>
  );
};

export default Account;
