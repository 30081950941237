import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { authAtom, selectedBranchIdAtom } from '@/store/atoms';

const Logout = () => {
  const queryClient = useQueryClient();
  const setSelectedBranchId = useSetAtom(selectedBranchIdAtom);
  const setAuth = useSetAtom(authAtom);

  useEffect(() => {
    setSelectedBranchId(null);
    setAuth({
      accessToken: '',
      refreshToken: '',
    });
    queryClient.clear();
  }, []);

  return null;
};

export default Logout;
