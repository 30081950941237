import type {
  GetBranchDailyOperationRatesParams,
  GetBranchDailySharesParams,
  GetContractsParams,
} from '@/types/universal';

const queryKeys = {
  universal: {
    getMyInformation: () => ['getMyInformation'],
    getMyPermissions: () => ['getMyPermissions'],
    getBranchDailyShares: (params: GetBranchDailySharesParams) => [
      'getBranchDailyShares',
      params,
    ],
    getBranchDailyOperationRates: (
      params: GetBranchDailyOperationRatesParams
    ) => ['getBranchDailyOperationRates', params],
    getUnitOperationRates: (branchId: number) => [
      'getUnitOperationRates',
      branchId,
    ],
    getUnitCompositionRates: (branchId: number) => [
      'getUnitCompositionRates',
      branchId,
    ],
    getAdjustments: () => ['getAdjustments'],
    getContracts: (params: GetContractsParams) => ['getContracts', params],
  },
};

export default queryKeys;
