import styled from '@emotion/styled';
import { Menu as AntdMenu } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import tw from 'twin.macro';

import { MENU_GROUPS, ROUTES } from '@/Route';
import { useMyPermissions } from '@/hooks/queries/universal';
import type { RouteKey } from '@/types/app';

const Menu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { availableMenuKeys } = useMyPermissions();

  return (
    <StyledMenu
      items={MENU_GROUPS.map((group) => ({
        key: group.key,
        label: group.label,
        type: 'group',
        children: Object.entries(ROUTES)
          .filter(([key, route]) => {
            return (
              route?.menuGroupKey === group.key &&
              availableMenuKeys.includes(key)
            );
          })
          .map(([key, route]) => ({
            key,
            label: route.title,
            icon: route.icon,
          })),
      }))}
      selectedKeys={[
        Object.entries(ROUTES).find(([, route]) =>
          pathname.includes(route.path)
        )?.[0] ?? '',
      ]}
      onClick={({ key }) => {
        const { path } = ROUTES[key as RouteKey];

        if (path.startsWith('http')) {
          window.open(path, '_blank');
        } else {
          navigate(path);
        }
      }}
    />
  );
};

export default Menu;

const StyledMenu = styled(AntdMenu)`
  &&& {
    ${tw`bg-transparent border-0`}

    .ant-menu-item-group {
      &:not(:first-of-type) {
        ${tw`mt-[12px]`}
      }

      &-title {
        ${tw`hidden p-0 font-bold text-[12px] text-[rgba(0, 0 ,0, 0.45)]`}
      }

      &-list {
        ${tw`mt-[12px]`}
      }
    }

    .ant-menu-item {
      ${tw`flex flex-row items-center gap-[12px] m-0 p-[8px] text-[16px] text-[rgba(0, 0 ,0, 0.45)] [&:active]:bg-transparent [&:hover]:bg-transparent`}

      &:not(:first-of-type) {
        ${tw`mt-[12px]`}
      }

      &-selected {
        ${tw`text-[rgba(0, 0, 0, 0.88)] bg-transparent`}
      }

      &-icon {
        ${tw`w-[24px] h-[24px]`}
      }

      .ant-menu-title-content {
        ${tw`m-0 leading-[1.5]`}
      }
    }
  }
`;
