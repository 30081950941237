import { useAtomValue } from 'jotai';
import tw from 'twin.macro';

import Table from '@/components/Table';
import { UNIT_TYPE } from '@/constants/unit';
import { useUnitCompositionRates } from '@/hooks/queries/universal';
import { selectedBranchIdAtom } from '@/store/atoms';

const ShareComposition = () => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const query = useUnitCompositionRates(selectedBranchId as number, {
    enabled: selectedBranchId !== null,
  });

  return (
    <Table
      columns={[
        {
          width: '33.33%',
          dataIndex: 'unitType',
          title: '유닛 타입',
          fixed: 'left',
          render: (value, record) => UNIT_TYPE[value as keyof typeof UNIT_TYPE],
        },
        {
          width: '33.33%',
          dataIndex: 'totalUnitTypeCount',
          title: '수량',
          render: (value) => `${value}개`,
        },
        {
          width: '33.33%',
          dataIndex: 'compositionRate',
          title: '구성 비율',
          render: (value) => `${value}%`,
        },
      ]}
      dataSource={query.data}
      summary={(data) => {
        const totalCount = data.reduce((acc, row) => {
          return acc + row.totalUnitTypeCount;
        }, 0);
        const compositionRate = data.reduce((acc, row) => {
          return acc + row.compositionRate * 100;
        }, 0);

        return (
          <tr>
            <td css={tw`(sticky z-[2] left-0)!`}>합계</td>
            <td>{totalCount}개</td>
            <td>
              {compositionRate / 100 > 100 ? 100 : compositionRate / 100}%
            </td>
          </tr>
        );
      }}
      rowKey={(row) => row.unitType}
    />
  );
};

export default ShareComposition;
