export const CONTRACT_STATUS = {
  RESERVED: '계약중',
  CONTRACT_END: '계약 종료',
  CANCELED: '계약 취소',
} as const;

export const CUSTOMER_TYPE = {
  NORMAL: '개인회원',
  BUSINESS: '법인회원',
} as const;
